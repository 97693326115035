import type { ActionTree } from 'vuex'
import type { SystemState } from './typing'
import type { RootState } from '@/store/root-state'

// import { SET_VERSION } from './mutations'
// import { selectVersion } from '@/api/study/cpns/study-version'

export const actions: ActionTree<SystemState, RootState> = {
  // [SET_VERSION]({ commit }, id: string) {
  //   return new Promise<void>((resolve, reject) => {
  //     selectVersion({ id: id })
  //       .then(res => {
  //         commit(SET_VERSION, res.data.name)
  //         resolve()
  //       })
  //       .catch((e: Error) => {
  //         reject(e)
  //       })
  //   })
  // }
}
