import type { SystemState } from './typing'

export const state: SystemState = {
  selectedStudy: {},
  activeKey: 'CO',
  proxy: ['CO'],
  isLocked: false,

  selectedSystem: {},
  selectedSystemId: ''
}

export const initState: SystemState = Object.assign({}, state)
