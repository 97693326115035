import type { UserState } from './typing'

export const state: UserState = {
  token: '',
  username: '',
  userNo: '',
  realName: '',
  nickname: '',
  userCode: '',
  projectCode: [],
  projectName: [],
  avatar: '',
  extra: {},
  role: undefined,
  dept: undefined,
  allowRouters: [],
  verifyInfo: {},

  vendorTypeList: [], // 系统的默认供应商列表
  trainFlag: false // 用户是否完成培训
}

export const initState: UserState = Object.assign({}, state)
