import type { ActionTree } from 'vuex'
import type { RouteRecordRaw } from 'vue-router'
import type { UserState, maPermission } from './typing'
import type { RootState } from '@/store/root-state'
import {
  RESET_CURRENT_USER,
  SET_ROUTERS,
  SET_TOKEN,
  SET_AVATAR,
  SET_INFO,
  SET_VENDOR_LIST,
  SET_TRAIN_FLAG
} from './mutations'
import type { LoginParams } from '@/api/user/login'
import { postAccountLogin, getProjectPermission, postLogout, getDeptInfo } from '@/api/user/login'
import { default as router, routes } from '@/router'
import { selectTypeList } from '@/api/study/detail'
import { getTrainingStatus } from '@/api/introduce'

import RouteView from '@/layouts/route-view.vue'
import type { Component } from 'vue'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const GET_INFO = 'GET_INFO'
export const GET_USER = 'GET_USER'
export const GENERATE_ROUTES = 'GenerateRoutes'
export const GENERATE_ROUTES_DYNAMIC = ''
export const GET_VENDOR_LIST = 'GET_VENDOR_LIST'
export const GET_TRAIN_STATUS = 'GET_TRAIN_STATUS'

export const actions: ActionTree<UserState, RootState> = {
  [LOGIN]({ commit }, info: LoginParams) {
    return new Promise((resolve, reject) => {
      // call ajax
      postAccountLogin(info)
        .then(res => {
          commit(SET_TOKEN, res.result.token)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  [GET_INFO]({ commit }) {
    return new Promise((resolve, reject) => {
      getProjectPermission()
        .then(response => {
          const result = response.result

          commit(SET_AVATAR, result.avatar)
          commit(SET_INFO, result)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    }).then((res: any) => {
      // 获取当前登录人部门信息
      return new Promise((resolve, reject) => {
        const param = { username: res.result.username }
        getDeptInfo(param)
          .then(response => {
            commit('SET_DEPT', response.data)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    })
  },

  // 从路由表构建路由（前端对比后端权限字段过滤静态路由表）
  [GENERATE_ROUTES]({ commit }, info: maPermission[]) {
    return new Promise<RouteRecordRaw[]>(resolve => {
      addRoute(info, routes[0])

      const allowRoutes = routes[0].children
      router.addRoute(routes[0])

      commit(SET_ROUTERS, allowRoutes)
      resolve(allowRoutes)
    })
  },
  [LOGOUT]({ commit }) {
    return new Promise<void>(resolve => {
      postLogout().finally(() => {
        commit(SET_TOKEN, null)
        routes[0].children = []
        commit(SET_ROUTERS, [])
        commit(RESET_CURRENT_USER)
        resolve()
      })
    })
  },
  [GET_VENDOR_LIST]({ commit }) {
    selectTypeList({ type: 3 }).then(res => {
      if (res.code === 0) {
        const tempOptions = []
        res.data.forEach((item, index) => {
          tempOptions.push({ label: item.name, value: item.name, code: index + 1 + '' })
        })
        tempOptions.push({ label: '其它/Other', value: '其它/Other', code: '99' })
        commit(SET_VENDOR_LIST, tempOptions)
      }
    })
  },
  [GET_TRAIN_STATUS]({ commit }) {
    getTrainingStatus().then(res => {
      let flag = false
      if (res.data.total === res.data.complete) {
        flag = true
      }
      commit(SET_TRAIN_FLAG, flag)
    })
  }
}

const notAliveRouter = ['resource-index', 'resource-edit']
const addRoute = function (temp: any, route: any): any {
  temp.forEach(item => {
    if (item.hasChild) {
      const tempRoute = <any>{}
      tempRoute.path = item.path
      tempRoute.name = item.name
      tempRoute.meta = {
        icon: item.icon,
        title: item.meta.title,
        hideInMenu: item.hidden === 'true',
        hideChildrenInMenu: item.hideChildrenInMenu === 'true'
      }

      if (item.component === 'RouteView') {
        tempRoute.component = RouteView
      } else {
        if (item.hasChild) {
          tempRoute.meta.keepAlive = false
          tempRoute.meta.mergeTab = true
        }
        tempRoute.component = (): Component =>
          import(/* @vite-ignore */ '../../../views/' + item.component + '.vue')
      }

      if (item.redirect) {
        tempRoute.redirect = item.redirect
      }

      tempRoute.children = []
      route.children.push(tempRoute)
      addRoute(item.children, tempRoute)
    } else {
      const tempRoute = <any>{}
      tempRoute.path = item.path
      tempRoute.name = item.name
      tempRoute.meta = {
        icon: item.icon,
        title: item.meta.title,
        hideInMenu: item.hidden === 'true'
      }
      if (notAliveRouter.includes(item.name)) {
        tempRoute.meta.keepAlive = false
      }
      tempRoute.component = (): Component =>
        import(/* @vite-ignore */ '../../../views/' + item.component + '.vue')
      route.children.push(tempRoute)
    }
  })
}

// function improt
