import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bell_outlined = _resolveComponent("bell-outlined")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createBlock(_component_a_dropdown, {
    class: "ant-pro-dropdown ant-pro-dropdown-action",
    placement: "bottomRight",
    trigger: ['click'],
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    overlayClassName: "pro-components-header-notice-icon-index-container"
  }, {
    overlay: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_a_spin, {
          spinning: _ctx.loading,
          delay: 300
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["spinning"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(['noticeButton', { opened: _ctx.visible }])
      }, [
        _createVNode(_component_a_badge, {
          count: _ctx.count,
          style: { boxShadow: 'none' },
          class: "badge"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "bell", {}, () => [
              _createVNode(_component_bell_outlined, { class: "icon" })
            ], true)
          ]),
          _: 3
        }, 8, ["count"])
      ], 2)
    ]),
    _: 3
  }, 8, ["visible"]))
}