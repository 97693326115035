import type { MutationTree } from 'vuex'
import type { SystemState } from './typing'

export const SET_ACTIVEKEY = 'SET_ACTIVEKEY'
export const SET_PROXY = 'SET_PROXY'
export const SET_IS_LOCKED = 'SET_IS_LOCKED'
export const SET_SELECTED_SYSTEM = 'SET_SELECTED_SYSTEM'
export const SET_SELECTED_SYSTEM_ID = 'SET_SELECTED_SYSTEM_ID'

export const mutations: MutationTree<SystemState> = {
  [SET_ACTIVEKEY]: (state, payload: string) => {
    state.activeKey = payload
  },

  [SET_PROXY]: (state, payload: [string]) => {
    state.proxy = payload
  },

  [SET_IS_LOCKED]: (state, payload: boolean) => {
    state.isLocked = payload
  },

  [SET_SELECTED_SYSTEM]: (state, payload: { [key: string]: any }) => {
    state.selectedSystem = payload
  },

  [SET_SELECTED_SYSTEM_ID]: (state, payload: string) => {
    state.selectedSystemId = payload
  }
}
