import type { SystemState } from './typing'

export const state: SystemState = {
  selectedForm: {},
  activeKey: '1',
  formQueryParam: {},

  // 签字弹窗相关参数
  signVisible: false,
  url: null,

  // 拒绝相关
  rejectId: '',
  rejectVisible: false,
  rejectType: '',

  // apm 选择相关
  apmUserList: [],

  apmVisible: false,
  pmInfo: ''
}

export const initState: SystemState = Object.assign({}, state)
