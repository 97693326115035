import 'ant-design-vue/dist/antd.variable.less'
import '@surely-vue/table/dist/index.less'
import mitt from 'mitt'

const Mit = mitt()
declare module 'vue' {
  export interface ComponentCustomProperties {
    $Bus: typeof Mit
  }
}

// 在应用入口文件中使用: 如 main.js, app.jsx
import { createVersionPolling } from 'version-polling'

// 10分钟做一次轮询
createVersionPolling({
  appETagKey: '__APP_ETAG__',
  pollingInterval: 10 * 60 * 1000, // 单位为毫秒
  silent: process.env.NODE_ENV === 'development', // 开发环境下不检测
  onUpdate: self => {
    // 当检测到有新版本时，执行的回调函数，可以在这里提示用户刷新页面
    const result = confirm('页面有更新，点击确定刷新页面！')
    if (result) {
      self.onRefresh()
    } else {
      self.onCancel()
    }
    // 强制更新可以用alert
    // alert('有新版本，请刷新页面');
  }
})

import {
  Layout,
  Menu,
  Row,
  Col,
  Card,
  Form,
  Dropdown,
  Select,
  Button,
  Checkbox,
  Tabs,
  Tag,
  Input,
  DatePicker,
  TimePicker,
  Radio,
  Tooltip,
  Space,
  Steps,
  Divider,
  Descriptions,
  Alert,
  Result,
  Statistic,
  Popconfirm,
  Popover,
  Table,
  Avatar,
  List,
  Progress,
  Switch,
  Modal,
  Rate,
  ConfigProvider,
  Empty,
  Spin,
  Drawer,
  PageHeader,
  Carousel,
  BackTop,
  Upload,
  Badge,
  Pagination,
  Tree,
  Cascader,
  InputNumber,
  AutoComplete,
  Typography
} from 'ant-design-vue'
import STable from '@surely-vue/table'
import { createApp } from 'vue'
import router from './router'
import store from './store'
import locale from './locales'
import App from './App.vue'

import { ProProvider, PageContainer, TransformVnode } from '@/components'
import { useIcons } from '@/icons'
import Authority from './utils/authority/authority.vue'
import './app.less'
import './router/router-guards'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

import { Icon } from '@iconify/vue'
dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
const app = createApp(App)
app.config.globalProperties.$confirm = Modal.confirm
app.config.globalProperties.$Bus = Mit

app
  .use(router)
  .use(locale as any)
  .use(store)
  .use(STable)
  .use(Layout)
  .use(Menu)
  .use(Row)
  .use(Col)
  .use(Card)
  .use(Form)
  .use(Dropdown)
  .use(Select)
  .use(Button)
  .use(Checkbox)
  .use(Tabs)
  .use(Tag)
  .use(Input)
  .use(DatePicker)
  .use(TimePicker)
  .use(Radio)
  .use(Tooltip)
  .use(Space)
  .use(Steps)
  .use(Divider)
  .use(Descriptions)
  .use(Alert)
  .use(Result)
  .use(Statistic)
  .use(Popconfirm)
  .use(Popover)
  .use(Table)
  .use(Avatar)
  .use(List)
  .use(Progress)
  .use(Switch)
  .use(Modal)
  .use(Rate)
  .use(ConfigProvider)
  .use(Empty)
  .use(Spin)
  .use(Drawer)
  .use(PageHeader)
  .use(ProProvider)
  .use(Badge)
  .use(Carousel)
  .use(BackTop)
  .use(Upload)
  .use(Pagination)
  .use(Tree)
  .use(Cascader)
  .use(InputNumber)
  .use(AutoComplete)
  .use(Typography)
  .component(PageContainer.name, PageContainer)
  .component(TransformVnode.name, TransformVnode)
  .component(Authority.name, Authority)
  .component('Icon', Icon)
useIcons(app)

app.mount('#app')
