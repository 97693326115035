import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_transform_vnode = _resolveComponent("transform-vnode")!
  const _component_sub_menu = _resolveComponent("sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createBlock(_component_a_menu, _mergeProps({
    theme: _ctx.theme,
    mode: _ctx.mode,
    "open-keys": _ctx.openKeys,
    "selected-keys": _ctx.selectedKeys,
    onOpenChange: _ctx.handleOpenChange,
    onSelect: _ctx.handleSelect
  }, _ctx.dynamicProps, {
    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('mouseenter', $event))),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('mouseleave', $event)))
  }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          ((!menu.children || menu.meta?.hideChildrenInMenu) && !menu.meta?.hideInMenu)
            ? (_openBlock(), _createBlock(_component_transform_vnode, {
                key: menu.path,
                by: _ctx.customItem,
                opt: menu
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_component_a_menu_item, {
                    onMouseenter: ($event: any) => (_ctx.$emit('itemHover', { key: menu.path })),
                    key: menu.path
                  }, {
                    icon: _withCtx(() => [
                      (menu.meta.icon !== undefined)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(menu.meta.icon), {
                            key: menu.meta.icon
                          }))
                        : (_ctx.collapsed && menu.meta.collapsedIcon !== undefined)
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent(menu.meta.collapsedIcon), {
                              key: menu.meta.collapsedIcon
                            }))
                          : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.t(menu.meta.title)), 1)
                    ]),
                    _: 2
                  }, 1032, ["onMouseenter"]))
                ]),
                _: 2
              }, 1032, ["by", "opt"]))
            : (menu.children)
              ? (_openBlock(), _createBlock(_component_sub_menu, {
                  key: menu.path,
                  onItemHover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('itemHover', $event))),
                  menu: menu,
                  collapsed: _ctx.collapsed
                }, null, 8, ["menu", "collapsed"]))
              : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ]),
    _: 1
  }, 16, ["theme", "mode", "open-keys", "selected-keys", "onOpenChange", "onSelect"]))
}