import type { GetterTree } from 'vuex'
import type { SystemState } from './typing'
import type { RootState } from '@/store/root-state'

export const getters: GetterTree<SystemState, RootState> = {
  selectedForm: state => state.selectedForm,
  formQueryParam: state => state.formQueryParam,

  activeKey: state => state.activeKey,
  signVisible: state => state.signVisible,
  url: state => state.url,

  rejectVisible: state => state.rejectVisible,
  rejectId: state => state.rejectId
}
