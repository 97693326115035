import type { MutationTree } from 'vuex'
import type { SystemState } from './typing'

export const SET_SELECTED_MENU = 'SET_SELECTED_MENU'
export const SET_SELECTED_MENU_ID = 'SET_SELECTED_MENU_ID'
export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE'
export const SET_TYPE_OPTIONS = 'SET_TYPE_OPTIONS'

export const SET_ACTIVE_KEY = 'SET_ACTIVE_KEY'
export const SET_FORM_QUERY_PARAM = 'SET_FORM_QUERY_PARAM'

export const mutations: MutationTree<SystemState> = {
  [SET_SELECTED_MENU]: (state, payload: { [Key: string]: any }) => {
    state.selectedMenu = payload
  },

  [SET_SELECTED_MENU_ID]: (state, payload: string) => {
    state.selectedMenuId = payload
  },

  [SET_SELECTED_ROLE]: (state, payload: { [Key: string]: any }) => {
    state.selectedRole = payload
  },

  [SET_TYPE_OPTIONS]: (
    state,
    payload: { value: string; label: string; typeName: string; name: string }[]
  ) => {
    state.typeOptions = payload
  },

  [SET_ACTIVE_KEY]: (state, payload: string) => {
    state.activeKey = payload
  },

  [SET_FORM_QUERY_PARAM]: (state, payload: { [key: string]: any }) => {
    state.formQueryParam = payload
  }
}
