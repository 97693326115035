import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_notice_list = _resolveComponent("notice-list")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_notice_dropdown = _resolveComponent("notice-dropdown")!

  return (_openBlock(), _createBlock(_component_notice_dropdown, {
    class: "action",
    count: _ctx.userInfo && _ctx.userInfo.unreadCount,
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        class: "notice-tab",
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        centered: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.noticesConfig, ({ key, title, emptyText, showViewMore }) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
              key
                ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                    key: key,
                    tab: title
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_notice_list, {
                        title: title,
                        count: _ctx.unreadMsgs[key],
                        list: _ctx.noticeData[key],
                        emptyText: emptyText,
                        showViewMore: showViewMore,
                        onItemClick: _ctx.changeReadState,
                        clearText: "Empty",
                        viewMoreText: "See more",
                        onClear: ($event: any) => (_ctx.handleNoticeClear(title, key)),
                        onViewMore: ($event: any) => (_ctx.handleViewMore(key)),
                        showClear: ""
                      }, {
                        extra: _withCtx((notice) => [
                          (notice.extra && notice.status)
                            ? (_openBlock(), _createBlock(_component_a_tag, {
                                key: 0,
                                style: {"margin-right":"0"},
                                color: _ctx.color[notice.status]
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(notice.extra), 1)
                                ]),
                                _: 2
                              }, 1032, ["color"]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(notice.extra), 1)
                              ], 64))
                        ]),
                        _: 2
                      }, 1032, ["title", "count", "list", "emptyText", "showViewMore", "onItemClick", "onClear", "onViewMore"])
                    ]),
                    _: 2
                  }, 1032, ["tab"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    _: 1
  }, 8, ["count", "loading"]))
}