import type { AxiosRequestConfig, AxiosError } from 'axios'
import axios, { AxiosResponse } from 'axios'
import type { ResponseBody } from '@/api/typing'
import { localStorage } from '@/utils/local-storage'
import { STORAGE_TOKEN_KEY } from '@/store/mutation-type'
import { notification } from 'ant-design-vue'
import router from '@/router'
// import { loginRoutePath } from '@/router/define-meta';

// 这里是用于设定请求后端时，所用的 Token KEY
// 可以根据自己的需要修改，常见的如 Access-Token，Authorization
// 需要注意的是，请尽量保证使用中横线`-` 来作为分隔符，
// 避免被 nginx 等负载均衡器丢弃了自定义的请求头
export const REQUEST_TOKEN_KEY = 'Access-Token'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error: AxiosError): Promise<any> => {
  if (error.response) {
    const { data = {}, status, statusText } = error.response
    const message = data && (data.message || data.msg)
    // 403 无权限
    if (data.code === '10002') {
      router.push({ path: '/user/login' })
      return
    }
    if (status === 403) {
      notification.error({
        message: 'Forbidden',
        description: message || statusText
      })
    }

    if (status === 500) {
      if (error.response.request.responseType === 'blob') {
        const reader = new FileReader()
        reader.onload = (e: any) => {
          const errData = JSON.parse(e.target.result)
          if (errData.message) {
            const message = errData && (errData.message || errData.msg)
            notification.error({
              message: 'Forbidden',
              description: message
            })
          }
        }
        reader.readAsText(error.response.data)
      } else {
        notification.error({
          message: 'Forbidden',
          description: message || statusText
        })
      }
    }

    // 401 未登录/未授权
    if (status === 401 && data.result && data.result.isLogin) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      // 如果你需要直接跳转登录页面
      // location.replace(loginRoutePath);
    }
  }
  return Promise.reject(error)
}

// 请求拦截器
const requestHandler = (
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  const savedToken = localStorage.get(STORAGE_TOKEN_KEY)
  // 如果 token 存在
  // 让每个请求携带自定义 token, 请根据实际情况修改
  if (savedToken) {
    config.headers[REQUEST_TOKEN_KEY] = savedToken
  }
  return config
}

// Add a request interceptor
request.interceptors.request.use(requestHandler, errorHandler)

// 响应拦截器
const legalCode = [0, 200, '0', '200']
const boldType = ['blob', 'arraybuffer']
const responseHandler = (
  response: AxiosResponse
): ResponseBody<any> | AxiosResponse<any> | Promise<any> | any => {
  const message = response.data && (response.data.message || response.data.msg)

  if (response.data.code === '10001' || response.data.code === 10001) {
    window.sessionStorage.setItem('login-error', message)
    notification.error({
      message: 'Forbidden',
      description: message
    })
    return Promise.reject(message)
  }

  if (response.data.code === '10002' || response.data.code === 10002) {
    notification.error({
      message: 'Forbidden',
      description: message
    })

    router.push({ path: '/user/login' })
    return Promise.reject(message)
  }

  if (response.data.code === 500 || response.data.code === '500') {
    notification.error({
      message: 'Error',
      description: message
    })
    return Promise.reject(message)
  }

  if (response.headers['content-disposition']) {
    //将响应文件名信息直接添加挂载rea.data
    response.data.contentDisposition = response.headers['content-disposition']
    response.data.fileName = window.decodeURI(response.headers['content-disposition'].split('=')[1])
    return response.data
  }

  if (
    legalCode.includes(response.data.code) ||
    (boldType.includes(response.request?.responseType) && response.status === 200)
  ) {
    return response.data
  } else {
    notification.error({
      message: 'Error',
      description: message
    })
    return Promise.reject(message)
  }
}

// Add a response interceptor
request.interceptors.response.use(responseHandler, errorHandler)

export { AxiosResponse }

export default request
