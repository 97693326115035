// import type { Component } from 'vue'
// import { defineAsyncComponent, h } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import type { MenuDataItem } from './typing'
import Layout from '@/layouts/index.vue'
// import UserLayout from '@/layouts/user-layout.vue';
import UserLayout2 from '@/layouts/user-layout2.vue'
// import RouteView from '@/layouts/route-view.vue'

// const AsyncWorkplace = defineAsyncComponent(
//   () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/workplace/index.vue')
// )
export const routes: MenuDataItem[] = [
  {
    name: 'index',
    path: '/',
    redirect: '/account',
    component: Layout,
    children: []
  }
]

export const staticRoutes: MenuDataItem[] = [
  {
    path: '/user',
    name: 'user',
    meta: { hideInMenu: true, title: 'pages.layouts.userLayout.title' },
    component: UserLayout2,
    children: [
      {
        path: '/user/login',
        name: 'login',
        meta: { title: 'pages.login.accountLogin.tab' },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/login.vue')
      },
      {
        path: '/user/register',
        name: 'register',
        meta: { title: 'pages.login.registerAccount' },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/register.vue')
      },
      {
        path: '/user/register-result',
        name: 'register-result',
        meta: { title: 'pages.login.registerAccount' },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/register-result.vue')
      },
      {
        path: '/user/verify',
        name: 'verify',
        meta: { title: 'pages.login.verify' },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/verify.vue')
      },
      {
        path: '/user/recover',
        name: 'recover',
        meta: { title: 'pages.login.recover', keepAlive: false },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/recover.vue')
      },
      {
        path: '/user/sso',
        name: 'sso',
        meta: { title: 'sso login' },
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/sso.vue')
      }
    ]
  },
  {
    name: 'exception500',
    path: '/exception/500',
    meta: { title: 'pages.exception.500.title' },
    component: () => import(/* webpackChunkName: "exception" */ '@/views/exception/500.vue')
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import(/* webpackChunkName: "exception" */ '@/views/exception/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  // history: createWebHistory(process.env.VITE_APP_PUBLIC_PATH),
  routes: staticRoutes,
  scrollBehavior: (to, from) => {
    if (to.path !== from.path) {
      setTimeout(() => {
        document.getElementById('app').scrollTop = 0
      })
    }
    return { top: 0 }
  }
})

export default router
