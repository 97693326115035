import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42cc7f17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "theme-color" }
const _hoisted_2 = { class: "theme-color-content" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_outlined = _resolveComponent("check-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colorList, ({ color, key }) => {
        return (_openBlock(), _createBlock(_component_a_tooltip, {
          key: color,
          title: _ctx.t(`app.setting.themecolor.${key}`)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "theme-color-block",
              style: _normalizeStyle({ backgroundColor: color }),
              onClick: ($event: any) => (_ctx.onChange(color))
            }, [
              (_ctx.value === color)
                ? (_openBlock(), _createBlock(_component_check_outlined, { key: 0 }))
                : _createCommentVNode("", true)
            ], 12, _hoisted_3)
          ]),
          _: 2
        }, 1032, ["title"]))
      }), 128))
    ])
  ]))
}