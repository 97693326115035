import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_page_header_content = _resolveComponent("page-header-content")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_grid_content = _resolveComponent("grid-content")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classNames)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.prefixedClassName}-warp`)
    }, [
      _createVNode(_component_a_page_header, {
        breadcrumb: 
          _ctx.showBreadcrumb ? { routes: _ctx.breadcrumb, itemRender: _ctx.customBreadcrumbRender } : null
        ,
        title: _ctx.title,
        "sub-title": _ctx.subTitle,
        onBack: _ctx.handleBack
      }, _createSlots({
        default: _withCtx(() => [
          (_ctx.hasContent || _ctx.hasExtraContent)
            ? (_openBlock(), _createBlock(_component_page_header_content, {
                key: 0,
                "prefix-cls": _ctx.prefixedClassName
              }, _createSlots({ _: 2 }, [
                (_ctx.hasContent)
                  ? {
                      name: "content",
                      fn: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "content")
                      ]),
                      key: "0"
                    }
                  : undefined,
                (_ctx.hasExtraContent)
                  ? {
                      name: "extraContent",
                      fn: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "extraContent")
                      ]),
                      key: "1"
                    }
                  : undefined
              ]), 1032, ["prefix-cls"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (_ctx.hasTags)
          ? {
              name: "tags",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "tags")
              ]),
              key: "0"
            }
          : undefined,
        (_ctx.hasExtra)
          ? {
              name: "extra",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "extra")
              ]),
              key: "1"
            }
          : undefined,
        (_ctx.hasFooter || _ctx.tabList.length > 0)
          ? {
              name: "footer",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "footer", {}, () => [
                  (_ctx.tabList.length > 0)
                    ? (_openBlock(), _createBlock(_component_a_tabs, {
                        key: 0,
                        "active-key": _ctx.tabActiveKey,
                        onChange: _ctx.handleTabChange
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (tab) => {
                            return (_openBlock(), _createBlock(_component_a_tab_pane, {
                              key: tab.key,
                              tab: tab.tab
                            }, null, 8, ["tab"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["active-key", "onChange"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              key: "2"
            }
          : undefined
      ]), 1032, ["breadcrumb", "title", "sub-title", "onBack"])
    ], 2),
    _createVNode(_component_grid_content, null, {
      default: _withCtx(() => [
        (_ctx.hasChildren)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`${_ctx.prefixedClassName}-children-content`)
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 2))
}