import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ad4269e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"opacity":"1"} }
const _hoisted_2 = { style: {"opacity":"1"} }
const _hoisted_3 = { style: {"opacity":"0.5"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_setting_outlined = _resolveComponent("setting-outlined")!
  const _component_block_checkbox = _resolveComponent("block-checkbox")!
  const _component_body_wrapper = _resolveComponent("body-wrapper")!
  const _component_theme_color = _resolveComponent("theme-color")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_layout_change = _resolveComponent("layout-change")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    visible: _ctx.visible,
    width: 300,
    getContainer: _ctx.getContainer,
    onClose: _cache[8] || (_cache[8] = () => _ctx.setShow(false)),
    style: {"z-index":"99"},
    placement: "right"
  }, {
    handle: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.prefixCls}-handle`),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickShowButton && _ctx.handleClickShowButton(...args)))
      }, [
        (_ctx.visible)
          ? (_openBlock(), _createBlock(_component_close_outlined, {
              key: 0,
              style: _normalizeStyle(_ctx.iconStyle)
            }, null, 8, ["style"]))
          : (_openBlock(), _createBlock(_component_setting_outlined, {
              key: 1,
              style: _normalizeStyle(_ctx.iconStyle)
            }, null, 8, ["style"]))
      ], 2)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.prefixCls}-content`)
      }, [
        _createVNode(_component_body_wrapper, {
          key: "pageStyle",
          title: _ctx.t('app.setting.pagestyle')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_block_checkbox, {
              value: _ctx.navTheme,
              list: _ctx.themeList.themeList,
              onChange: _cache[1] || (_cache[1] = val => _ctx.handleChange('theme', val))
            }, null, 8, ["value", "list"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_body_wrapper, {
          key: "themeColor",
          title: _ctx.t('app.setting.themecolor')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_theme_color, {
              value: _ctx.genStringToTheme(_ctx.primaryColor),
              colorList: _ctx.themeList.colorList,
              onChange: _cache[2] || (_cache[2] = val => _ctx.handleChange('primaryColor', val))
            }, null, 8, ["value", "colorList"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_a_divider),
        _createVNode(_component_body_wrapper, {
          key: "mode",
          title: _ctx.t('app.setting.navigationmode')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_block_checkbox, {
              value: _ctx.layout,
              onChange: _cache[3] || (_cache[3] = val => _ctx.handleChange('layout', val))
            }, null, 8, ["value"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_layout_change, {
          contentWidth: _ctx.contentWidth,
          fixedHeader: _ctx.fixedHeader,
          fixSiderbar: _ctx.fixSidebar,
          layout: _ctx.layout,
          splitMenus: _ctx.splitMenus,
          onChange: _cache[4] || (_cache[4] = ({ type, value }) => _ctx.handleChange(type, value))
        }, null, 8, ["contentWidth", "fixedHeader", "fixSiderbar", "layout", "splitMenus"]),
        _createVNode(_component_a_divider),
        _createVNode(_component_body_wrapper, {
          title: _ctx.t('app.setting.othersettings')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_list, { split: false }, {
              default: _withCtx(() => [
                _createVNode(_component_a_list_item, null, {
                  actions: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      size: "small",
                      style: {"width":"100px"},
                      value: _ctx.transitionName || 'null',
                      onChange: _cache[5] || (_cache[5] = val => _ctx.handleChange('transition', val))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select_option, { value: "null" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Null")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_select_option, { value: "slide-fadein-up" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Slide Up")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_select_option, { value: "slide-fadein-right" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Slide Right")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_select_option, { value: "fadein" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Fade In")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_select_option, { value: "zoom-fadein" }, {
                          default: _withCtx(() => [
                            _createTextVNode("Zoom")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.t('app.setting.transitionname')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tooltip, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_list_item, null, {
                      actions: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          size: "small",
                          checked: _ctx.multiTab,
                          onChange: _cache[6] || (_cache[6] = () => _ctx.handleChange('multiTab', !_ctx.multiTab))
                        }, null, 8, ["checked"])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('app.setting.multitab')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tooltip, {
                  placement: "left",
                  title: _ctx.t('app.setting.multitab.fixed.hit')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_list_item, null, {
                      actions: _withCtx(() => [
                        _createVNode(_component_a_switch, {
                          size: "small",
                          checked: _ctx.multiTabFixed,
                          disabled: !_ctx.multiTab && !_ctx.fixedHeader,
                          onChange: _cache[7] || (_cache[7] = () => _ctx.handleChange('multiTabFixed', !_ctx.multiTabFixed))
                        }, null, 8, ["checked", "disabled"])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          style: _normalizeStyle({ opacity: !_ctx.multiTab ? '0.5' : '1' })
                        }, _toDisplayString(_ctx.t('app.setting.multitab.fixed')), 5)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_a_list_item, null, {
                  actions: _withCtx(() => [
                    _createVNode(_component_a_switch, {
                      size: "small",
                      checked: false,
                      disabled: true
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('app.setting.weakmode')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["title"])
      ], 2)
    ]),
    _: 1
  }, 8, ["visible", "getContainer"]))
}