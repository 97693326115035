import type { GetterTree } from 'vuex'
import type { SystemState } from './typing'
import type { RootState } from '@/store/root-state'

export const getters: GetterTree<SystemState, RootState> = {
  selectedMenuId: state => state.selectedMenuId,
  selectedMenu: state => state.selectedMenu,
  selectedRole: state => state.selectedRole,
  typeOptions: state => state.typeOptions
}
