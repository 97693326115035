import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-pro-header-account-name anticon" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_menu_divider = _resolveComponent("a-menu-divider")!
  const _component_logout_outlined = _resolveComponent("logout-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_ctx.currentUser && _ctx.currentUser.dept)
    ? (_openBlock(), _createBlock(_component_a_dropdown, {
        key: 0,
        class: "ant-pro-dropdown ant-pro-dropdown-action",
        placement: "bottomRight",
        overlayClassName: "pro-components-header-dropdown-index-container"
      }, {
        overlay: _withCtx(() => [
          _createVNode(_component_a_menu, {
            class: "ant-pro-dropdown-menu",
            "selected-keys": []
          }, {
            default: _withCtx(() => [
              (_ctx.menu)
                ? (_openBlock(), _createBlock(_component_a_menu_divider, { key: 0 }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_menu_item, {
                key: "logout",
                onClick: _ctx.handleLogout
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_logout_outlined)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" 退出登录 ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            _createVNode(_component_a_avatar, {
              size: "small",
              src: "",
              class: "ant-pro-header-account-avatar"
            }),
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.currentUser.dept.realName), 1)
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, [
        _createVNode(_component_a_spin, {
          size: "small",
          style: { marginLeft: 8, marginRight: 8 }
        })
      ]))
}