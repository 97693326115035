import type { GetterTree } from 'vuex'
import type { SystemState } from './typing'
import type { RootState } from '@/store/root-state'

export const getters: GetterTree<SystemState, RootState> = {
  selectedStudy: state => state.selectedStudy,
  activeKey: state => state.activeKey,
  proxy: state => state.proxy,
  isLocked: state => state.isLocked,
  selectedSystem: state => state.selectedSystem,
  selectedSystemId: state => state.selectedSystemId
}
