import type { Module } from 'vuex'
import { state } from './state'
import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'
import type { SystemState } from './typing'
import type { RootState } from '@/store/root-state'

export const form: Module<SystemState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
