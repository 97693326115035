import request from '@/utils/request'
import type { RegResponse } from '@/api/typing'

export async function addStudyUser(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/add_user_by_study', params)
}

export async function deleteStudyUser(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/delete_study_user', params)
}

export async function queryStudyUser(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/find_user_by_study', params)
}

export async function queryNotInStudyUser(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/find_non_user_by_study', params)
}

// APM 人员相关
// 添加 APM
export async function addApmUser(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/add_apm_by_study', params)
}

// 删除 APM
export async function deleteApmUser(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/delete_study_apm', params)
}

// 查询 APM 人员
export async function queryApmUser(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/select_apm_page', params)
}

// 查询 APM 人员列表
export async function queryApproverList(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/select_apm_list', params)
}
// APM 人员相关 end

// 邮件通知人相关
// 添加 Mail 人员
export async function addMailMember(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/add_notice_by_study', params)
}

// 删除 Mail 人员
export async function deleteMailMember(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/delete_study_notice', params)
}

// 查询 Mail 人员
export async function queryMailMember(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study_user/select_notice_page', params)
}
// 邮件通知人相关 end

// 项目系统相关
// 新增 修改项目系统
export async function setStudySystem(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_system/add_or_update', params)
}

// 查询项目系统 page
export async function queryStudySysPage(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_system/select_page', params)
}

// 查询项目系统 list
export async function queryStudySysList(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_system/select_list', params)
}

// 删除项目系统
export async function deleteStudySystem(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_system/delete_by_id', params)
}

// 添加系统的选项
export async function selectTypeList(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/type/select_type_list', params)
}

// 系统角色导出
export async function exportStudySystem(params: { [key: string]: any }): Promise<any> {
  return request.post('/study/study_system/export', params, { responseType: 'blob' })
}

// 系统角色导入
export async function uploadStudySystem(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post(
    '/study/study_system/upload',
    {
      ...params
    },
    { timeout: 20000 }
  )
}
// 项目系统相关 end

// 项目系统角色相关
// 新增 修改项目角色
export async function setStudySysRole(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_system/add_or_update_role', params)
}

// 查询项目系统 list
export async function queryStudySysRoleList(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_system/select_role_list', params)
}

// 删除项目角色
export async function deleteStudySysRole(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_system/delete_role_by_id', params)
}
// 项目系统角色相关 end

// 项目Site相关
// 设置Site
export async function setStudySite(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_site/add_or_update', params)
}

// 查询项目系统 page
export async function queryStudySitePage(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_site/select_page', params)
}

// 查询项目系统 list
export async function queryStudySiteList(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_site/select_list', params)
}

// 删除项目角色
export async function deleteStudySite(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_site/delete_by_id', params)
}

// 导入项目 site
export async function uploadStudySite(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/study/study_site/upload', params, { timeout: 20000 })
}

// 导出项目 site
export async function exportStudySite(params: { [key: string]: any }): Promise<any> {
  return request.post('/study/study_site/export', params, { responseType: 'blob' })
}
// 项目Site相关 end

// CO 人员引入相关
export async function queryCoPage(params: { [key: string]: any }): Promise<any> {
  return request.post('/study_user/select_co_page', params)
}

export async function addCoMember(params: { [key: string]: any }): Promise<any> {
  return request.post('/study_user/add_co_by_study', params)
}

export async function deleteCo(params: { [key: string]: any }): Promise<any> {
  return request.post('/study_user/delete_study_co', params)
}
// CO 人员引入相关 end
