import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: {
      position: 'relative',
    },
    class: _normalizeClass(_ctx.wrapperCls)
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (!_ctx.disabled && _ctx.base64Url)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.waterMakrCls),
          style: _normalizeStyle({
        zIndex: _ctx.zIndex,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundSize: `${_ctx.gapX + _ctx.width}px`,
        pointerEvents: 'none',
        backgroundRepeat: 'repeat',
        backgroundImage: `url('${_ctx.base64Url}')`,
        ..._ctx.markStyle,
      })
        }, null, 6))
      : _createCommentVNode("", true)
  ], 2))
}