<template>
  <a-dropdown
    v-if="currentUser && currentUser.dept"
    class="ant-pro-dropdown ant-pro-dropdown-action"
    placement="bottomRight"
    overlayClassName="pro-components-header-dropdown-index-container"
  >
    <span>
      <a-avatar size="small" src="" class="ant-pro-header-account-avatar" />
      <span class="ant-pro-header-account-name anticon">{{ currentUser.dept.realName }}</span>
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-dropdown-menu" :selected-keys="[]">
        <a-menu-divider v-if="menu" />
        <a-menu-item key="logout" @click="handleLogout">
          <template #icon><logout-outlined /></template>
          退出登录
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue'
import { LogoutOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { LOGOUT } from '@/store/modules/user/actions'
import { notification } from 'ant-design-vue'

export default defineComponent({
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const { t } = useI18n()
    const handleToCenter = () => {
      router.push({ path: '/account/center' })
    }
    const handleToSettings = () => {
      router.push({ path: '/account/settings' })
    }
    const internalInstance = getCurrentInstance()
    const handleLogout = () => {
      let global = internalInstance.appContext.config.globalProperties

      global.$confirm({
        title: '提示',
        content: '确认注销登录 ?',
        onOk: () => {
          store
            .dispatch(`user/${LOGOUT}`)
            .then(() => {
              router.push({ path: '/user/login' })
            })
            .catch(err => {
              notification.error({
                message: '错误',
                description: err.message
              })
            })
        },
        onCancel() {}
      })
    }

    return {
      t,
      handleToCenter,
      handleToSettings,
      handleLogout
    }
  },
  components: {
    LogoutOutlined
  }
})
</script>

<style lang="less">
body {
  @import './header-dropdown.less';
  .ant-pro-header-account-name {
    vertical-align: unset;
  }
  .ant-pro-header-account-avatar {
    margin: 4px 8px 4px 0;
    color: @primary-color;
    vertical-align: top;
    background: hsla(0, 0%, 100%, 0.85);
  }
  :deep(.ant-pro-global-header-collapsed-button) {
    margin-left: 5px;
    font-size: 15px;
  }
}
</style>
