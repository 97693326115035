import type { MutationTree } from 'vuex'
import type { SystemState } from './typing'

export const SET_SELECTED_FORM = 'SET_SELECTED_FORM'
export const SET_FORM_QUERY_PARAM = 'SET_FORM_QUERY_PARAM'
export const SET_SIGN_INFO = 'SET_SIGN_INFO'
export const SET_ACTIVE_KEY = 'SET_ACTIVE_KEY'

export const SET_REJECT = 'SET_REJECT'
export const SET_APM_USER_LIST = 'SET_APM_USER_LIST'
export const SET_APM = 'SET_APM'

export const mutations: MutationTree<SystemState> = {
  [SET_SELECTED_FORM]: (state, payload: { [key: string]: any }) => {
    state.selectedForm = payload
  },

  [SET_FORM_QUERY_PARAM]: (state, payload: { [key: string]: any }) => {
    state.formQueryParam = payload
  },

  [SET_SIGN_INFO]: (state, payload: { [key: string]: any }) => {
    state.signVisible = payload.visible
    state.url = payload.url
  },

  [SET_ACTIVE_KEY]: (state, payload: string) => {
    state.activeKey = payload
  },

  [SET_REJECT]: (state, payload: { [key: string]: any }) => {
    state.rejectVisible = payload.visible
    state.rejectId = payload.id
    state.rejectType = payload.type
  },

  [SET_APM_USER_LIST]: (state, payload: { [key: string]: any }[]) => {
    state.apmUserList = payload
  },

  [SET_APM]: (state, payload: { [key: string]: any }) => {
    state.apmVisible = payload.visible
    state.pmInfo = payload.pmInfo
  }
}
