import type { PureSettings } from '@/components/base-layouts/defaultSettings'

export interface AppState extends PureSettings {
  lang: string
  device: 'mobile' | 'desktop' | string
  transitionName: string
  multiTab: boolean
  multiTabFixed: boolean
  tableSize: string
}

export const state: AppState = {
  lang: 'zh-CN',
  device: 'desktop',
  layout: 'side',
  navTheme: 'dark',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixedSidebar: true,
  menu: { locale: false },
  splitMenus: false,
  title: 'EDC',
  primaryColor: '#1890ff',
  colorWeak: false,
  transitionName: 'slide-fadein-up',
  multiTab: true,
  multiTabFixed: true,
  tableSize: 'small'
}
