import request from '@/utils/request'

export type LoginType = 'account' | 'telephone'
export type LoginStatus = 'ok' | 'error'
import type { RegResponse } from '../typing'

export interface LoginParams {
  type: LoginType
  username: string
  password: string
}

export interface LoginResp {
  type: LoginType
  success: boolean
  result: any
  token: string
  // currentAuthority: string;
}

export interface UserInfo {
  id: string | number
  address: string
  avatar: string
  country: string
  email: string
  group: string
  name: string
  phone: string
  signature: string
  role: {
    [key: string]: any
  }
}

export interface CaptchaResp {
  captcha: number
}

export interface SmsCaptchaRequest {
  username: string
  mail: string
}

// 后端的结构体定义
export type RouteItem = {
  id: number | string
  parentId: number | string
  name: string
  path: string
  redirect: string
  component: string
  meta: {
    title: string | false
    icon?: string
    target?: '_blank' | '_self'
    hideInMenu?: boolean
    hideChildrenInMenu?: boolean
    authority?: string | string[]
    [key: string]: any
  }
}

export async function postAccountLogin(params: LoginParams) {
  return request.post<LoginParams, LoginResp>('/user/login', params)
}

export function sso(parameter) {
  return request.post('/user/sso', parameter)
}

export async function getCurrentUser() {
  return request.post<any, UserInfo>('/user/info')
}

export async function getProjectPermission() {
  return request.get<any, any>('/user/info')
}

export function getDeptInfo(parameter) {
  return request.post<any, any>('/user/find_user_by_username', parameter)
}

export async function getCurrentUserNav() {
  return request.get<any, RouteItem[]>('/currentUserNav')
}

export async function postLogout() {
  return request.post<any>('/user/logout')
}

// 用户注册时 获取验证码
export async function getSmsCaptcha(params: SmsCaptchaRequest): Promise<RegResponse> {
  return request.post('/user/register_verify', params, { timeout: 60000 })
}

// 变更邮箱时 获取验证码
export async function getSmsCaptchaUpdate(params: SmsCaptchaRequest): Promise<RegResponse> {
  return request.post('/user/verify_mail_for_update', params)
}

export async function updateUser(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/user/update_info', params)
}
