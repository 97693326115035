import type { SystemState } from './typing'

export const state: SystemState = {
  selectedMenuId: '',
  selectedMenu: {},

  selectedRole: {},
  typeOptions: [],

  activeKey: '',
  formQueryParam: {}
}

export const initState: SystemState = Object.assign({}, state)
