import type { MutationTree } from 'vuex'
import type { UserState } from './typing'
import { initState } from './state'
import { STORAGE_TOKEN_KEY } from '@/store/mutation-type'
import ls from '@/utils/local-storage'

export const SET_TOKEN = 'SET_TOKEN'
export const SET_AVATAR = 'SET_AVATAR'
export const SET_ROLE = 'SET_ROLE'
export const SET_INFO = 'SET_INFO'
export const SET_DEPT = 'SET_DEPT'
export const SET_ROUTERS = 'SET_ROUTERS'
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER'
export const SET_VERIFY_INFO = 'SET_VERIFY_INFO'
export const SET_USER_CODE = 'SET_USER_CODE'
export const SET_PROJECT_CODE = 'SET_PROJECT_CODE'
export const SET_VENDOR_LIST = 'SET_VENDOR_LIST'
export const SET_TRAIN_FLAG = 'SET_TRAIN_FLAG'

export const mutations: MutationTree<UserState> = {
  [SET_TOKEN]: (state, token: string) => {
    state.token = token
    ls.set(STORAGE_TOKEN_KEY, token)
  },
  [SET_INFO]: (state, info) => {
    state.username = info.username
    state.userNo = info.userNo
    state.realName = info.realName
    state.projectCode = info.roles.map(item => item.code)
    state.projectName = info.roles.map(item => item.name)
  },
  [SET_DEPT]: (state, dept) => {
    state.dept = dept
  },
  [SET_ROLE]: (state, role) => {
    state.role = role
  },
  [SET_AVATAR]: (state, avatar) => {
    state.avatar = avatar
  },
  [SET_ROUTERS]: (state, allowRoutes) => {
    state.allowRouters = allowRoutes
  },
  [RESET_CURRENT_USER]: state => {
    Object.assign(state, initState)
  },
  [SET_VENDOR_LIST]: (state, payload) => {
    state.vendorList = payload
  },

  [SET_USER_CODE]: (state, payload) => {
    state.userCode = payload
  },

  [SET_PROJECT_CODE]: (state, payload) => {
    state.projectCode = payload
  },

  [SET_VERIFY_INFO]: (state, payload) => {
    state.verifyInfo = payload
  },

  [SET_TRAIN_FLAG]: (state, payload: boolean) => {
    state.trainFlag = payload
  }
}
