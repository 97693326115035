import request from '@/utils/request'
import type { RegResponse } from '@/api/typing'

// 完成培训
export async function completeTaining(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/training/complete_training', params)
}

// 查询人员对培训完成情况
export async function getTrainingStatus(): Promise<RegResponse> {
  return request.post('/training/get_training_status')
}

// 查询培训列表
export async function queryTrainingList(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/training/select_training_list', params)
}

// 查询培训记录
export async function queryRecord(params: { [key: string]: any }): Promise<RegResponse> {
  return request.post('/training/select_training_page', params)
}

// 导出培训记录
export async function exportRecord(params: { [key: string]: any }): Promise<any> {
  return request.post('/training/export_training_record', params, { responseType: 'blob' })
}
