import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b81279e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ant-pro-multi-tab-fixed"
}
const _hoisted_2 = { class: "ant-pro-multi-tab-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ellipsis_outlined = _resolveComponent("ellipsis-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_ctx.cacheListLength)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.fixed)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true),
        _createVNode(_component_a_tabs, _mergeProps({ type: "editable-card" }, _ctx.$attrs, {
          activeKey: _ctx.activeKey,
          style: {
        margin: 0,
        paddingTop: '3px',
        width: _ctx.sideWidth
      },
          "hide-add": "",
          class: { 'ant-pro-multi-tab-wrap': true, 'ant-pro-multi-tab-wrap-fixed': _ctx.fixed },
          onChange: _ctx.handleActiveKeyChange
        }), {
          rightExtra: _withCtx(() => [
            _createVNode(_component_a_dropdown, { placement: "bottomRight" }, {
              overlay: _withCtx(() => [
                _createVNode(_component_a_menu, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "close-other",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeOther(_ctx.route.path))),
                      disabled: _ctx.cacheListLength === 1
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 关闭其他 ")
                      ]),
                      _: 1
                    }, 8, ["disabled"]),
                    _createVNode(_component_a_menu_item, {
                      key: "refresh",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleReloadPage()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("刷新当前页")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ellipsis_outlined, {
                  rotate: 90,
                  class: "ant-dropdown-link ant-pro-multi-tab-dropdown-menu-btn"
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.cacheList, (item, index) => {
              return (_openBlock(), _createBlock(_component_a_tab_pane, {
                class: "contextmenu-wrap",
                key: item.route.path,
                closable: false
              }, {
                tab: _withCtx(() => [
                  _createVNode(_component_a_dropdown, { trigger: ['contextmenu'] }, {
                    overlay: _withCtx(() => [
                      _createVNode(_component_a_menu, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_menu_item, {
                            disabled: _ctx.cacheListLength === 1,
                            key: "close-other",
                            onClick: ($event: any) => (_ctx.closeOther(item.route.path))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 关闭其他 ")
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"]),
                          _createVNode(_component_a_menu_item, {
                            key: "close-left",
                            disabled: index === 0,
                            onClick: ($event: any) => (_ctx.closeLeft(item.route.path))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 关闭到左侧 ")
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"]),
                          _createVNode(_component_a_menu_item, {
                            disabled: index + 1 === _ctx.cacheListLength,
                            key: "close-right",
                            onClick: ($event: any) => (_ctx.closeRight(item.route.path))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 关闭到右侧 ")
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"]),
                          _createVNode(_component_a_menu_item, {
                            onClick: ($event: any) => (_ctx.handleReloadPage(item.route.path))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("刷新当前页")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, [
                        _createTextVNode(_toDisplayString(item.tabTitle ? _ctx.t(`${item.tabTitle}`) : _ctx.t(`${item.route.meta.title}`)) + " ", 1),
                        (_ctx.store.current === item.route.path)
                          ? (_openBlock(), _createBlock(_component_reload_outlined, {
                              key: "reload",
                              class: "ant-pro-multi-tab-reload-btn",
                              onClick: ($event: any) => (_ctx.handleReloadPage(item.route.path)),
                              spin: _ctx.spin
                            }, null, 8, ["onClick", "spin"]))
                          : _createCommentVNode("", true),
                        (_ctx.cacheListLength > 1 && !item.lock)
                          ? (_openBlock(), _createBlock(_component_close_outlined, {
                              key: "close",
                              class: "ant-pro-multi-tab-close-btn",
                              onClick: e => _ctx.handleClose(e, item.route.path)
                            }, null, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 16, ["activeKey", "style", "class", "onChange"])
      ], 64))
    : _createCommentVNode("", true)
}